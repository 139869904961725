<template>
    <div id="investor-subpage">
        <div class="subpage-navs">
            <div class="breadcrumbs">
                <el-breadcrumb>
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                        {{ item.text }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <ul class="navs" v-if="navs.length > 0 && $route.params.page !== navsType[4]">
                <li v-for="(item, index) in navs" :key="index" :class="activedIndex === index ? 'actived' : ''">
                    <span @click="jumpTo(item)">{{ $i18n.locale === 'en' ? item.enName : item.cnName }}</span>
<!--                    <router-link :to="item.templateUrl">{{ $i18n.locale === 'en' ? item.enName : item.cnName }}</router-link>-->
                </li>
            </ul>
        </div>
        <div class="content">
            <div class="info" v-if="$route.params.page === navsType[0]">
                <img :src="klineUrl" alt="">
            </div>
            <div class="announcement" v-if="$route.params.page === navsType[1]">
                <div class="list">
                    <ul>
                        <li v-for="(item, index) in dataList" :key="index">
                            <div class="date">
                                {{ item.updateTime | formatYearMonth }}
                                <p>{{ item.updateTime | formatDay }}</p>
                            </div>
                            <div class="text">
                                <div>{{ isEn ? item.enTitle : item.cnTitle }}</div>
                                <span class="icon-more" @click="toDetail(item)"></span>
                            </div>
                        </li>
                    </ul>
                    <div class="pagination">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="getSubpageList"
                            :current-page.sync="pageCurrent"
                            :page-size="pageSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div class="governance" v-if="$route.params.page === navsType[2]">
                <div class="btns" v-if="governanceBtns">
                    <button :class="['standard', governanceBtnIdx === index ? 'actived' : '']"
                            @click="changeGovernanceList(item.id, index)"
                            v-for="(item, index) in governanceBtns"
                            :key="index"
                        >{{ isEn ? item.enName : item.cnName }}</button>
                </div>
                <div class="table" v-if="dataList.length > 0">
                    <ComponentCardListH5 :dataList="dataList" dateVar = "updateTime"/>
                    <el-table
                            :data="dataList"
                            style="width: 100%">
                        <el-table-column
                                show-overflow-tooltip
                                :label="isEn ? governanceBtns[governanceBtnIdx].enName : governanceBtns[governanceBtnIdx].cnName"
                                :prop="isEn ? 'enTitle' : 'cnTitle'">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px; cursor: pointer;" @click="toDetail(scope.row)">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="180"
                                :label="isEn ? 'updateTime' : '发布时间'"
                                prop="updateTime"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.updateTime | formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="140"
                                :label="isEn ? 'readCounts' : '点击次数'"
                                prop="readCounts"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.readCounts }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="getSubpageList"
                            :current-page.sync="pageCurrent"
                            :page-size="pageSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div class="reciprocation" v-if="$route.params.page === navsType[3]">
                <div class="table">
                    <ComponentCardListH5 :dataList="dataList" dateVar = "updateTime"/>
                    <el-table
                            :data="dataList"
                            style="width: 100%">
                        <el-table-column
                                label="标题"
                                show-overflow-tooltip
                                :prop="isEn ? 'enTitle' : 'cnTitle'">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px; cursor: pointer;" @click="toDetail(scope.row)">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="180"
                                label="发布时间"
                                prop="updateTime"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.updateTime | formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="140"
                                label="点击次数"
                                prop="readCounts"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.readCounts }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="getSubpageList"
                                :current-page.sync="pageCurrent"
                                :page-size="pageSize"
                                :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="investorProtection" v-if="$route.params.page === navsType[4]">
                <div class="table">
                    <ComponentCardListH5 :dataList="dataList" dateVar = "updateTime"/>
                    <el-table
                            :data="dataList"
                            style="width: 100%">
                        <el-table-column
                                label="投资者保护宣传"
                                show-overflow-tooltip
                                :prop="isEn ? 'enTitle' : 'cnTitle'">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px; cursor: pointer;" @click="toDetail(scope.row)">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="180"
                                label="发布时间"
                                prop="updateTime"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.updateTime | formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="140"
                                label="点击次数"
                                prop="readCounts"
                                align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.readCounts }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="getSubpageList"
                                :current-page.sync="pageCurrent"
                                :page-size="pageSize"
                                :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="interactive" v-if="$route.params.page === navsType[5]">
                <div class="lf">
                    <img src="../../assets/images/interactive_pic.png" alt="">
                </div>
                <div class="form">
                    <el-form ref="form" :model="form" label-width="120px">
                        <el-form-item label="姓名:" required>
                            <el-input v-model="form.name" placeholder="请输入您的名字"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" required>
                            <el-input v-model="form.phone" placeholder="请输入您的联系电话号码"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱:">
                            <el-input v-model="form.email" placeholder="请输入您的联系电话号码"></el-input>
                        </el-form-item>
                        <el-form-item label="留言类型:">
                            <el-select v-model="form.type" placeholder="请选择留言类型">
                                <el-option label="玩具产品咨询" value="玩具产品咨询"></el-option>
                                <el-option label="投资者交流" value="投资者交流"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="留言内容:" required>
                            <el-input type="textarea" v-model="form.content" placeholder="请输入您的留言内容"></el-input>
                        </el-form-item>
                        <el-form-item align="center">
                            <el-button type="primary" @click="submit">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {urls} from '@/lib/common'
    import qs from "qs";
    import ComponentCardListH5 from '@/components/ComponentCardListH5'
    export default {
        name: "subpage",
        components: {
            ComponentCardListH5
        },
        data() {
            return {
                breadcrumbs: [],
                navs: [],
                klineUrl: 'http://image.sinajs.cn/newchart/daily/n/sh603398.gif',
                activedIndex: 0,
                pageCurrent: 1,
                pageSize: 8,
                total: 0,
                isEn: false,
                hasArticle: false, // 是否含有文章, 列表类型专用
                navType: 0, // 0:单页, 1:列表, 2:外链, 3:表单提交
                governanceBtns: [],
                detail: '',
                dataList: [],
                parentId: '',
                governanceBtnIdx: 0,
                form:{
                    name: "",
                    phone: "",
                    email: "",
                    type: "",
                    content: ""
                },
                //        基本信息，  公司公告         公司治理         股东回报           保护投资者            互动交流         投资者教育
                navsType: ['info', 'announcement', 'governance', 'reciprocation', 'investorProtection', 'interactive', 'investorEducation'],


            }
        },
        watch: { // watch 中不能用箭头函数，不然this指向出问题
            "$route.params.page": function(val) {
                this.matchNavs(val);
            },
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            getInformations() {
                this.$http.get(urls.getSubNavs + this.parentId).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                        const navName = this.$route.params.page;
                        for (var i in this.navs) {
                            if (this.navs[i].data && (this.navs[i].templateUrl.indexOf(this.navsType[4]) !== -1 || this.navs[i].templateUrl.indexOf(this.navsType[2]) !== -1)) {
                                this.governanceBtns = this.navs[i].data;
                                break;
                            }
                        }
                        this.matchNavs(navName);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getBreadcrumbs(id) {
                this.$http.get(urls.getBreadcrumb + id).then(res => {
                    if (res.data.status === 200) {
                        const result = res.data.data;
                        const homePage = {
                            text: this.isEn ? 'Home' : '首页',
                            pathName: 'Home',
                            disabled: false,
                            href: '/'
                        }
                        this.breadcrumbs = [
                            homePage,
                            {
                                text: this.isEn ? result[0].enName : result[0].cnName,
                                pathName: result[0].templateUrl,
                                disabled: false,
                                href: result[0].templateUrl
                            }, {
                                text: this.isEn ? result[1].enName : result[1].cnName,
                                pathName: result[1].templateUrl,
                                disabled: true,
                                href: ''
                            }
                        ]
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            changeGovernanceList(id, index) {
                this.pageCurrent = 1;
                if (index !== undefined && index !== null && index !== '') this.governanceBtnIdx = index;
                this.getSubpageList()
            },
            matchNavs(name) {
                this.navs.map((item, index) => {
                    if (item.templateUrl.indexOf(name) !== -1) {
                        this.pageCurrent = 1;
                        this.activedIndex = index;
                        this.hasArticle = item.hasArticle;
                        this.navType = item.type;
                        this.getBreadcrumbs(item.id);
                        if (this.navType === 1) {
                            if (this.governanceBtns.length > 0 && name === this.navsType[2]) {
                                this.changeGovernanceList(this.governanceBtns[this.governanceBtnIdx].id)
                            } else {
                                this.getSubpageList();
                            }
                        } else if (this.navType === 2) { // 外跳链接，暂时按官网直接跳过去
                            window.open('http://www.csrc.gov.cn/pub/newsite/tzzbh1/tbtzzjy/tbfxff/');
                        } else {
                            this.getContent(item.id)
                        }
                        return
                    }
                })
            },
            getSubpageList() {
                const params = {
                    navigationId: this.governanceBtns[this.governanceBtnIdx].id,
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent
                }
                const query = qs.stringify(params)
                this.$http.get(urls.getSubpageList + '?' + query).then(res => {
                    if (res.data.status === 200) {
                        this.dataList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getContent(id) {
                this.$http.get(urls.getContent + id).then(res => {
                    if (res.data.status === 200) {
                        this.detail = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            submit() {
                if (!this.form.name) {
                    this.$message.warning('请输入姓名');
                    return false
                }
                if (!this.form.phone) {
                    this.$message.warning('请输入联系电话');
                    return false
                }
                if (!this.form.content) {
                    this.$message.warning('请输入留言内容');
                    return false
                }
                const params = this.form;
                this.$http.post(urls.createMessage, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('留言成功');
                        this.form = {
                            name: "",
                            phone: "",
                            email: "",
                            type: "",
                            content: ""
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            jumpTo(item) {
                if (item.type === 2) {
                    window.open(item.templateUrl);
                } else {
                    this.$router.push({
                        path: item.templateUrl
                    })
                }
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.t) {
                this.governanceBtnIdx = parseInt(window.atob(decodeURIComponent(this.$route.query.t))) - 1;
            }
            if (this.$route.query && this.$route.query.n) {
                this.parentId = window.atob(decodeURIComponent(this.$route.query.n));
            }
            this.getInformations();
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #investor-subpage {
        padding-top: 1px;
        background: url('../../assets/images/investor/nav_bg.png') no-repeat right -80px;
        background-size: 600px auto;
        .subpage-navs {
            width: 1300px;
            margin: 0 auto;
            color: #7C7FA2;
            .breadcrumbs {
                margin: 2vw 0;
                .link {
                    padding: 0 5px;
                }
                @{deep} .el-breadcrumb{
                    font-size: 18px;
                    font-weight: normal;
                    .el-breadcrumb__inner a, 
                    .el-breadcrumb__separator,
                    .el-breadcrumb__inner.is-link{
                        font-weight: normal;
                        color:#625F6F;
                    }
                }
            }
            .navs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2vw 0 4vw;
                li {
                    width: 148px;
                    text-align: center;
                    height: 64px;
                    line-height: 64px;
                    position: relative;

                    span {
                        font-size: 18px;
                        color: #7C7FA2;
                        cursor: pointer;
                    }

                    &:not(:last-child)::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: -30%;
                        height: 30px;
                        margin-top:-15px;
                        border-right: 1px solid #eaeaea;
                    }
                    &:last-child {
                        border: 0;
                    }
                    &.actived {
                        background: linear-gradient(to bottom, #FF4B7E, #D93C46);
                        border-radius: 6px;
                        box-shadow: 0 0.52vw 1vw rgba(217, 60, 70, 0.8);
                        &::after {
                            border-right-color: #eaeaea;
                        }
                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .content {
            .icon-more {
                display: inline-block;
                width: 1.458vw;
                height: 0.9375vw;
                background: url('../../assets/images/about/icon_more.png') no-repeat center center;
                background-size: 100% 100%;
            }
            >div {
                width: 1300px;
                margin: auto;
                padding-bottom: 40px;
            }
            .pagination {
                margin-top: 30px;
                text-align: center;
            }
            .info {
                margin: auto;
            }
            .announcement {
                width: 100%;
                padding-top: 1px;
                background: url('../../assets/images/investor/list_bg.png') no-repeat center top;
                .list {
                    margin-top: 2vw;
                    width: 1300px;
                    margin-left: auto;
                    margin-right: auto;
                    ul {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        li {
                            width: 48%;
                            background: #fff;
                            position: relative;
                            margin-bottom: 50px;
                            font-size: 20px;
                            box-shadow: 0 6px 8px 5px #ddd;
                            border-radius: 0 10px 0 0;
                            .date {
                                background: #34254C;
                                text-align: center;
                                position: absolute;
                                left: 0;
                                color: #fff;
                                padding: 3% 2%;
                                border-radius: 0 0 30px 0;
                                height: 100%;
                                p {
                                    font-size: 48px;
                                    margin-top: 8%;
                                }
                            }
                            .text {
                                padding: 0 12% 0 24%;
                                line-height: 40px;
                                height: 130px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                position: relative;
                                >div {
                                    display: -webkit-box;
                                    overflow: hidden;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                                .icon-more {
                                    position: absolute;
                                    right: 1vw;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .governance,
            .reciprocation,
            .investorProtection {
                padding: 0 20px;
                .table {
                    margin-top: 30px;
                }
                .btns {
                    button {
                        width: 130px;
                        height: 40px;
                        line-height:40px;
                        margin-right: 15px;
                        border: 1px solid #ddd;
                        outline: none;
                        color: #5A5A5A;
                        font-size: 16px;
                        transition: all .3s linear;
                        border-radius: 5px;
                        &.actived {
                            background-color: #2D2141;
                            color: #fff;
                        }
                    }
                }
                @{deep} .el-table tr{
                    font-size: 14px;
                    height: 50px;
                    .cell{
                        line-height: 30px;
                    }
                }
            }
            .interactive {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .lf {
                    flex: 1;
                }
                @{deep}.form {
                    flex: 2;
                    margin-left: 20px;
                    .el-select{
                        width: 100%;
                    }
                    .el-form-item__label{
                        font-size: 18px;
                        color:#000;
                    }
                    .el-textarea {
                        .el-textarea__inner {
                            resize: none;
                        }
                    }
                    .el-input__inner{
                        color:#000;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #investor-subpage{
            background: none;
            .subpage-navs{
                width: 100%;
                padding: 0 4%;
                .breadcrumbs {
                    @{deep} .el-breadcrumb{
                        .fontFn(12);
                        font-weight: normal;
                    }
                }
                .navs{
                    margin:0;
                    padding:4% 0;
                    display: block;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        width: 33.3%;
                        .fontFn(30,line-height);
                        .fontFn(30,height);
                        a{
                            .fontFn(12);
                        }

                        &:not(:last-child)::after{
                            content: none;
                        }
                    }
                }
            }
            .content{
                &>div{
                    width: 92%;
                }
                .announcement{
                    width: 92%;
                    background: none;
                    .list{
                        width: 100%;
                        ul{
                            li{
                                width: 100%;
                                .fontFn(12);
                                margin-bottom: 6%;
                                .date{
                                    width: 18%;
                                    padding-top: 2%;
                                    height: 100%;
                                    p{
                                        .fontFn(20);
                                        margin-top:12%;
                                    }
                                }
                                .text{
                                    padding: 2% 14% 2% 24%;
                                    .fontFn(80,height);
                                    .fontFn(24,line-height);
                                    .icon-more{
                                        .fontFn(16,width);
                                        .fontFn(12,height);
                                        right: 4%;
                                    }
                                }
                            }
                        }
                    }
                }
                .interactive{
                    .lf{display: none;}
                    @{deep} .form{
                        margin-left: 0;
                    }
                }
                
                .governance,
                .reciprocation,
                .investorProtection{
                    padding: 0;
                    .table{
                        .el-table{
                            display: none;
                        }
                    }
                }

                .governance{
                    .btns{
                        button{
                            .fontFn(12);
                            .fontFn(96,width);
                            .fontFn(36,height);
                            margin-right: 4%;
                        }
                    }
                }
            }
        }

    }
</style>